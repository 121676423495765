import './MainScreen.css';
import './Story.css';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import { useUserAuth } from './UserAuthContext.js';

function FinishLogin() {
    const userAuth = useUserAuth();
    const navigate = useNavigate();
    
    useEffect(() => {
        (async () => {
            const email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                console.log('No email stored in localStorage');
                // TODO: redirect to login
                return;
            }
            if (!userAuth.checkSignInWithEmailLink(window.location.href)) {
                console.log('Not isSignInWithEmailLink');
                // TODO: redirect to login
                return;
            }
            try {
                await userAuth.doSignInWithEmailLink(email, window.location.href);
                window.localStorage.removeItem('emailForSignIn');
                navigate('/');
            } catch(e) {
                console.error(e);
            }
        })();
    }, []);

    return (
        <div className='mainscreen'>
            <div className='loader'>
                <div className='inner one'></div>
                <div className='inner two'></div>
                <div className='inner three'></div>
            </div>
        </div>
    );
}

export default FinishLogin;
