import './PayWall.css';

function PayWall(props) {
    const { productTitle, productPrice, open, onClose, onClick } = props;
    return (
        <div className={`paywall-cover paywall-cover-${open ? 'open' : 'close'}`} onClick={ onClose }>
            <div className='paywall-main' onClick={e => e.stopPropagation()}>
                <div className='paywall-main-header'>
                    Premium Adventures
                    <div className='paywall-main-cover' />
                    <div className='paywall-description'>
                        Our premium adventures will take you on an amazing journey. Click the button below to purchase and enjoy "{productTitle}".
                    </div>
                    <div className='paywall-button' onClick={onClick} >
                        {productPrice}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PayWall;
