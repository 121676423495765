import { init, track, identify, Identify, revenue, Revenue } from '@amplitude/analytics-browser';

const AMPLITUDE_API_KEY = "c53000d67c83865db0d449a92215133a";

init(AMPLITUDE_API_KEY);


const analytics = {
    logEvent: (event, eventProperties = null) => {
        track(event, eventProperties);
    },
    setUser: (userId, email) => {
        const identifyObj = new Identify();
        identify(identifyObj, {
            user_id: userId,
            email: email
        });
    },
    resetUser: () => {
        const identifyObj = new Identify();
        identify(identifyObj);
    },
    logRevenue: (userId, productId, price, quantity) => {
        const event = new Revenue()
            .setProductId(productId)
            .setPrice(price)
            .setQuantity(quantity);

        revenue(event);
    }
};


export default analytics;
