import './Login.css';
import { useState } from 'react';
import { useUserAuth } from './UserAuthContext.js';
import analytics from './analytics.js';

function Login(props) {
    const { open, onClose } = props;
    const [ value, setValue ] = useState('');
    const [ emailSent, setEmailSent ] = useState(null);
    const [ error, setError ] = useState(null);
    const [ sending, setSending ] = useState(null);
    const userAuth = useUserAuth();

    async function onClick() {
        try { analytics.logEvent('Login', {email: value }); } catch(e) {}
        try {
            setSending(true);
            await userAuth.doSendSignInLinkToEmail(value);
            window.localStorage.setItem('emailForSignIn', value);
            setEmailSent(value);
        } catch (e) {
            console.error(e);
            setError(true);
            setSending(false);
        }
    }

    return (
        <div className={`login-cover login-cover-${open ? 'open' : 'close'}`} onClick={ emailSent ? () => {} : onClose }>
            <div className='login-main' onClick={e => e.stopPropagation()}>
                <div className='login-main-header'>
                    Login
                </div>
                {!emailSent && (
                    <div>
                        <div className='login-description'>
                            Please enter you email and press OK
                        </div>
                        <input 
                            value={value} 
                            onChange={(e) => { setValue(e.target.value); setError(false); }} 
                            style={{
                                width: 'calc(100% - 24px)', 
                                padding: '12px 6px', 
                                fontSize: '16px', 
                                borderRadius: '6px',
                                border: error ? '4px solid #f44336' : '',
                            }} 
                        />
                        <div className='login-button' onClick={onClick} style={ sending ? { opacity: 0.3, pointerEvents: 'none' } : {} } >
                            OK
                        </div>
                    </div>
                )}
                {emailSent && (
                    <div>
                        <div className='login-description' style={{ paddingTop: '36px' }}>
                            Please click on the link we've sent to {emailSent} to log in.
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Login;
