/* eslint-disable react-hooks/exhaustive-deps */
import './Story.css';
import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';
import { useUserAuth } from './UserAuthContext.js';
import NetworkError from './NetworkError.js';
import * as Sentry from '@sentry/react';
import analytics from './analytics.js';

const MAX_STEPS_TO_SAVE = 5; // will be duplicated, to make sure we also take the responses
const MAX_ATTEMPTS = 5;
const TIME_BETWEEN_ATTEMPTS = 2000;

const DOMAIN = 'api.timelessquests.com';

function Story() {
    const [ runningPrompt, setRunningPrompt ] = useState([]);
    const { storyId } = useParams();
    const userAuth = useUserAuth();
    const [ stage, setStage ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    const [ controlPanelHidden, setControlPanelHidden ] = useState(true);
    const [ controlPanelBounce, setControlPanelBounce ] = useState(true);
    const [ stageImage, setStageImage ] = useState({});
    const [ networkError, setNetworkError ] = useState(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const chat = queryParams.get('chat')==1;

    useEffect(() => {
        (async () => {
            setNetworkError(false);
            try { analytics.logEvent('Story', { storyId, numPrompts: runningPrompt.length }); } catch(e) {}
            for (let i = 0 ;i < MAX_ATTEMPTS ; i++) {
                try {
                    // generate the prompt to take
                    const recentPrompt = (runningPrompt.length > MAX_STEPS_TO_SAVE * 2)?runningPrompt.slice(0 - MAX_STEPS_TO_SAVE * 2):runningPrompt;

                    if (!chat) {
                        const { data } = await axios.post(`https://${DOMAIN}/create-story-stage?accesstoken=${userAuth.accesstoken}&productId=${storyId}`, {
                            prompt: recentPrompt.join(''),
                        });
                        setStage(data);
                    } else {
                        // build the messages object
                        const messages = [];
                        for (let i = 0 ;i < recentPrompt.length ; i++) {
                            const role = (i % 2 === 0)?'assistant':'user';
                            messages.push({"role": role, "content": recentPrompt[i]});
                        }
                        const { data } = await axios.post(`https://${DOMAIN}/create-story-stage-chat?accesstoken=${userAuth.accesstoken}&productId=${storyId}`, {
                            prompt: messages
                        });
                        setStage(data);
                    }
                    setLoading(false);
                    break;
                } catch(e) {
                    if (i === MAX_ATTEMPTS - 1) {
                        setNetworkError(true);
                    }
                    Sentry.captureException(e);
                    console.error(e);
                }
                await new Promise(res => setTimeout(res, TIME_BETWEEN_ATTEMPTS));
            }
        })();
    }, [ runningPrompt, userAuth.accesstoken ]);

    useEffect(() => {
        if (!stage) return;
        (async () => {
            const imagePrompt = stage.image || stage.Image;
            if (!stage.imagePrediction.output && (!stageImage || !stageImage[imagePrompt])) {
                const { id: predictId } = stage.imagePrediction;
                let responseStatusJSON = null;
                let sleepTime = 600;
                do {
                    await new Promise(r => setTimeout(r, sleepTime));
                    //console.debug('get image', predictId, stage.imagePrediction);
                    try {
                        ({ data: responseStatusJSON } = await axios.post(`https://${DOMAIN}/check-stage-image`, { predictId }));
                    } catch (e) {
                        Sentry.captureException(e);
                    }

                    if (responseStatusJSON.status === 'running') {
                        sleepTime-=100;
                        sleepTime = Math.max(sleepTime, 300);
                    }
                } while (!responseStatusJSON.output);

                setStageImage({ ...stageImage, [imagePrompt]: responseStatusJSON.output[0] });
            } else {
                setStageImage({ ...stageImage, [imagePrompt]: stage.imagePrediction.output });
            }
        })();
    }, [ stage&&stage.imagePrediction ? stage.imagePrediction.id : null ]);
    
    function handleOptionSelect({ option }) {
        try { analytics.logEvent('Story', { storyId, option }); } catch(e) {}
        setLoading(true);
        setRunningPrompt([...runningPrompt, JSON.stringify(stage), `option ${option}, what next?`]);
    }

    function toggleControlPanelHidden() {
        setControlPanelBounce(false);
        setControlPanelHidden(!controlPanelHidden);
    }

    //console.log('Stage:', stage);

    if (!stage) {
        return (
            <div className='story'>
                <div className='border1' />
                <div className='border2' />
                <div className='border3' />
                <div className='border4' />
                <div className='border5' />
                <div className='loader'>
                    <div className='inner one'></div>
                    <div className='inner two'></div>
                    <div className='inner three'></div>
                </div>
                <NetworkError open={networkError} onClick={() => setRunningPrompt(_.clone(runningPrompt))} />
            </div>
        );
    } else {
        return (
            <div className='story'>
                <div className='border1' />
                <div className='border2' />
                <div className='border3' />
                <div className='border4' />
                <div className='border5' />
                <div className='centertext'>
                    <div style={{ opacity: 0.8 }}>
                        {_.map(runningPrompt, (p, i) => {
                            if (p.startsWith('option')) return null;
                            try {
                                const json = JSON.parse(p);
                                const chosenOption = runningPrompt[i + 1].split(' ')[1][0];
                                return (
                                    <div key={`step-${i}`} className='stageimagedescriptionwrapper' style={{ marginBottom: '12px' }}>
                                        {stageImage[json.image || json.Image] && (
                                            <div className='stageimage'>
                                                <div className='stageimage1' style={{ backgroundImage: `url(${stageImage[json.image || json.Image]})` }} />
                                                <div className='stageimage2' style={{ backgroundImage: `url(${stageImage[json.image || json.Image]})` }} />
                                            </div>
                                        )}
                                        <div style={{ flex: 1 }} >
                                            {json.description}
                                            <br/>
                                            (
                                                {_.map(json.options, (option, key) => (
                                                    <span key={`option-${key}`} style={{ paddingRight: '6px', fontWeight: key === chosenOption ? 800 : 400 }}>
                                                        <span style={{ textDecoration: key === chosenOption ? 'underline' : '' }}>
                                                            {key}.&nbsp;{option}
                                                        </span>
                                                        <span>
                                                            {key === 'd' ? '' : ', '}
                                                        </span>
                                                    </span>
                                                ))}
                                            )
                                        </div>
                                    </div>
                                );
                            } catch (e) {
                                return (
                                    <div>
                                        {p}
                                    </div>
                                );
                            }
                        })}
                    </div>
                    <div className='stageimagedescriptionwrapper'>
                        {!loading && stageImage[stage.image || stage.Image] && (
                            <div className='stageimage'>
                                <div className='stageimage1' style={{ backgroundImage: `url(${stageImage[stage.image || stage.Image]})` }} />
                                <div className='stageimage2' style={{ backgroundImage: `url(${stageImage[stage.image || stage.Image]})` }} />
                            </div>
                        )}
                        {!loading && !stageImage[stage.image || stage.Image] && (
                            <div className='stageimage' style={{ border: '2px solid rgb(220, 202, 176)', boxSizing: 'border-box', borderRadius: '50%' }} />
                        )}
                        {!loading && (
                            <div style={{ flex: 1 }} >
                                <div>
                                    {stage.description}
                                </div>
                                <div style={{ position: 'relative', padding: '30px 0px', marginTop: '20px' }}>
                                    <div className='buttonbordertop' />
                                    <div className='buttonbordertop2' />
                                    <div className='buttonborderbottom' />
                                    <div className='buttonborderbottom2' />
                                    {_.map(stage.options, (option, key) => (
                                        <div className='button' key={key} onClick={() => handleOptionSelect({ option: key })}>
                                            {key}.&nbsp;{option}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                    {loading && (
                        <div className='loader'>
                            <div className='inner one'></div>
                            <div className='inner two'></div>
                            <div className='inner three'></div>
                        </div>
                    )}
                </div>
                
                <div className={`controlpanel ${controlPanelBounce ? 'bounce' : ''} ${controlPanelHidden ? 'hidden' : ''}`}>
                    <div className='tongue' onClick={toggleControlPanelHidden}>
                        <i className='fa-duotone fa-sack' />
                    </div>
                    <div className='controlpanel-inner'>
                        <div style={{ flex: 1 }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                Health:&nbsp;{_.map(_.range(Math.floor(stage.health / 10)), c => <span key={`health${c}`} style={{ color: 'rgb(200, 0, 0)' }}><i className='fa-solid fa-heart-pulse'></i>&nbsp;</span>)}
                            </div>
                            <div style={{ display: 'none', alignItems: 'center' }}>
                                Your Journey:&nbsp;<i className='fa-duotone fa-play'></i><div className='journey'><i className='fa-sharp fa-solid fa-person-walking' style={{ left: `${Math.floor(stage.quest_progress * 145 / 100)}px` }}/></div><i className='fa-duotone fa-flag-checkered'></i>
                            </div>
                        </div>
                        <div style={{ flex: 1 }}>
                            <span>
                                Your Inventory:&nbsp;
                            </span>
                            <span>
                                {_.isArray(stage.inventory) ? (stage.inventory.length > 0 ? stage.inventory.join(' | ') : 'Nothing') : stage.inventory}
                            </span>
                        </div>
                    </div>
                </div>
                <NetworkError open={networkError} onClick={() => setRunningPrompt(_.clone(runningPrompt))} />
            </div>
        );
    }
}

export default Story;
