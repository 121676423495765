import './App.css';
import MainScreen from './MainScreen.js';
import Story from './Story.js';
import PaymentSuccess from './PaymentSuccess.js';
import FinishLogin from './FinishLogin.js';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={(<MainScreen />)} />
                <Route path='/PaymentSuccess' element={(<PaymentSuccess />)} />
                <Route path='/finish-login' element={(<FinishLogin />)} />
                <Route path='story/:storyId' element={(<Story />)} />
            </Routes>
            <ToastContainer />
        </BrowserRouter>
    );
}

export default App;
