import './MainScreen.css';
import './Story.css';
import { useEffect, useState } from 'react';
import queryString from 'query-string';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { useUserAuth } from './UserAuthContext.js';
import analytics from './analytics';

function PaymentSuccess() {
    const userAuth = useUserAuth();
    const [ emailSent, setEmailSent ] = useState(null);
    
    useEffect(() => {
        (async () => {
            const parsed = queryString.parse(window.location.search);
            const { data } = await axios(`https://api.timelessquests.com/payment-success?ses=${parsed.session_id}`);

            try {
                await userAuth.doSendSignInLinkToEmail(data.customer.email);
                window.localStorage.setItem('emailForSignIn', data.customer.email);
                setEmailSent(data.customer.email);
            } catch (e) {
                Sentry.captureException(e);
                console.error(e);
            }
            try { analytics.logRevenue(data.customer.email, 0, 0, 1); } catch (e) {}
        })();
    }, []);

    return (
        <div className='mainscreen'>
            {!emailSent && (
                <div className='loader'>
                    <div className='inner one'></div>
                    <div className='inner two'></div>
                    <div className='inner three'></div>
                </div>
            )}
            {emailSent && (
                <div style={{
                    position: 'fixed',
                    width: '100%',
                    textAlign: 'center',
                    top: '50%',
                    fontSize: '36px',
                    fontWeight: '800',
                }}>
                    Please click on the link we've sent to {emailSent} to finish the purchase.
                </div>
            )}
        </div>
    );
}

export default PaymentSuccess;
