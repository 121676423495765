import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import {
    createUserWithEmailAndPassword,
    sendSignInLinkToEmail,
    signInWithEmailAndPassword,
    signInWithEmailLink,
    isSignInWithEmailLink,
    onAuthStateChanged,
    signOut
} from 'firebase/auth';
import { auth } from './firebase';

import useConfig from './useConfig';
import analytics from './analytics.js';

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
    const config = useConfig();

    const [user, setUser] = useState({});
    const [ products, setProducts ] = useState([]);
    const [ accesstoken, setAccesstoken ] = useState(null);
    

    function logIn(email, password) {
        return signInWithEmailAndPassword(auth, email, password);
    }

    function doSendSignInLinkToEmail(email) {
        const actionCodeSettings = {
            url: `${window.location.protocol}//${window.location.host}/finish-login`,
            handleCodeInApp: true,
        };
        return sendSignInLinkToEmail(auth, email, actionCodeSettings);
    }

    function checkSignInWithEmailLink(url) {
        return isSignInWithEmailLink(auth, url);
    }

    function doSignInWithEmailLink(email, url) {
        return signInWithEmailLink(auth, email, url);
    }

    function signUp(email, password) {
        return createUserWithEmailAndPassword(auth, email, password);
    }

    function logOut() {
        return signOut(auth);
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentuser) => {
            //console.log('Auth', currentuser);
            try { analytics.logEvent('Auth', currentuser); } catch (e) {}
            setUser(currentuser);
            if (currentuser) {
                const { data } = await axios(`https://api.timelessquests.com/customer-products?idToken=${currentuser.accessToken}`);
                setProducts(data.products);
                setAccesstoken(data.accesstoken);
                setUser(currentuser);
            }
        });

        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <userAuthContext.Provider
            value={{ user, logIn, signUp, logOut, doSendSignInLinkToEmail, checkSignInWithEmailLink, doSignInWithEmailLink, products, accesstoken }}
        >
            {children}
        </userAuthContext.Provider>
    );
}

export function useUserAuth() {
    return useContext(userAuthContext);
}
