import './NetworkError.css';

function NetworkError(props) {
    const { open, onClose, onClick } = props;
    return (
        <div className={`networkerror-cover networkerror-cover-${open ? 'open' : 'close'}`} onClick={ onClose }>
            <div className='networkerror-main' onClick={e => e.stopPropagation()}>
                <div className='networkerror-main-header'>
                    Oh no, we have a network issue
                    <div className='networkerror-main-cover' />
                    <div className='networkerror-description'>
                        We can't seem to find our servers, did they finally shut down the internet?
                    </div>
                    <div className='networkerror-button' onClick={onClick} >
                        Retry
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NetworkError;

