import './MainScreen.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PayWall from './PayWall.js';
import Login from './Login.js';
import analytics from './analytics.js';
import { useUserAuth } from './UserAuthContext.js';

function MainScreen() {
    const navigate = useNavigate();
    const userAuth = useUserAuth();
    const [ paywall, setPaywall ] = useState({ open: false });
    const [ login, setLogin ] = useState(null);

    function onPay() {
        analytics.logEvent('MainScreen - Pay Clicked', paywall);
        window.location.href = `https://api.timelessquests.com/create-checkout-session?prid=${paywall.productId}&success_url=${window.location.protocol}//${window.location.host}/PaymentSuccess&cancel_url=${window.location.protocol}//${window.location.host}/`;
    }

    function _navigate(productId) {
        analytics.logEvent('MainScreen - Navigate', { productId });
        navigate(`./story/${productId}`);
    }

    return (
        <div className='mainscreen'>
            <div className='login'>
                {userAuth.user && (
                    <div>
                        {userAuth.user.email}
                        <span className='loginbutton' onClick={() => userAuth.logOut()}>
                            Logout
                        </span>
                    </div>
                )}
                {!userAuth.user && (
                    <div className='loginbutton' onClick={() => setLogin(true)}>
                        Login
                    </div>
                )}
            </div>
            <div style={{ fontSize: '48px', paddingLeft: '11%', paddingTop: '48px' }}>
                Hello Wanderer,
            </div>
            <div style={{ fontSize: '24px', paddingLeft: '11%', width: '78%' }}>
                Welcome to our world of adventure and mystery.
            </div>
            <div style={{ fontSize: '24px', paddingLeft: '11%', width: '78%' }}>
                Pick your quest, if you dare.
            </div>
            <div style={{ minHeight: '205px', maxWidth: '800px', margin: 'auto', position: 'relative' }} >
                {renderSign({
                    productId: 3, name: 'D&D Adventure', price: '$3.95',
                    onPay: setPaywall, products: userAuth.products, navigate: _navigate
                })}
                {renderSign({
                    productId: 2, name: 'King of the Storm', price: '$3.95',
                    onPay: setPaywall, products: userAuth.products, navigate: _navigate
                })}
                {renderSign({
                    productId: 4, name: 'Dating Adventures', price: '$3.95',
                    onPay: setPaywall, products: userAuth.products, navigate: _navigate
                })}
                <div style={{ paddingBottom: '70px' }} />
            </div>
            <div className='footer'>
                <a className='loginbutton' href='https://timelessquests.s3.us-east-1.amazonaws.com/terms-1.0.html' target='_blank'>Terms of Use</a>
                |
                <a className='loginbutton' href='https://timelessquests.s3.us-east-1.amazonaws.com/privacy-1.0.html' target='_blank'>Privacy</a>
            </div>

            <PayWall open={paywall.open} productTitle={(paywall || {}).name} productPrice={(paywall || {}).price} onClick={() => onPay()} onClose={() => setPaywall({...paywall, open: false})} />
            <Login open={!!login} onClose={() => setLogin(false)} />
        </div>
    );
}

function renderSign({ productId, name, products, onPay, navigate, price }) {
    const active = productId === 3 || !!products.find(p => ((p.productId === productId) && (p.active === 1)));
    return (
        <div className='sign' onClick={() => active ? navigate(productId) : onPay({ productId, name, price, open: true })}>
            {name}
            {!active && <i className='fa-duotone fa-lock' />}
        </div>

    )
}

export default MainScreen;
