import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ConfigContext from './ConfigContext';
import reportWebVitals from './reportWebVitals';
import { UserAuthContextProvider } from './UserAuthContext';

const config = {
    /** Application Config */
    app: {
        URL: `http://localhost:3000`,
        DIST_URL: 'http://localhost:8080',
        PUBLIC_URL: 'http://localhost:8080',
        RDS_HOST: 'localhost',
    },
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ConfigContext.Provider value={config}>
        <UserAuthContextProvider>
            <App />
        </UserAuthContextProvider>
    </ConfigContext.Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
