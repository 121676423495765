// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';

// Your web app's Firebase configuration

const firebaseConfig = {
    apiKey: 'AIzaSyCuGka-fkwY4bIFpQnsV6hBeULeZgKA7qs',
    authDomain: 'gpnd-4b17c.firebaseapp.com',
    projectId: 'gpnd-4b17c',
    storageBucket: 'gpnd-4b17c.appspot.com',
    messagingSenderId: '1016906468635',
    appId: '1:1016906468635:web:57e57b6bc27375af0f17be',
    measurementId: 'G-NV5VJ88ER0'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
// Initialize Realtime Database and get a reference to the service
export const database = getDatabase(app); // TODO: remove?
export default app;
